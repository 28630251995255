import React from "react";
import {
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

const TextareaField = React.forwardRef(
  ({ aria, label, name, type = "text", error, handleChange, isRequired, ...rest }, ref) => (
    <FormControl isInvalid={error ? true : false} isRequired={isRequired}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        style={{ height: "48px" }}
        ref={ref}
        onChange={handleChange}
        aria-label={aria}
        type={type}
        name={name}
        id={name}
        {...rest}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  ),
);

export default TextareaField;
