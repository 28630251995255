import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import gsap from "gsap";
import {
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
  Stack,
  Box,
  Heading,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { IoMailSharp } from "react-icons/io5";
import Layout from "../components/Layout/Layout";
import InputField from "../components/shared/Form/InputField/InputField";
import TextareaField from "../components/shared/Form/TextareaField/TextareaField";
import Seo from "../components/Seo/Seo";
import { ERROR_MESSAGES } from "../utils/constants";
import OvalIcon from "../images/vector/oval.svg";
import FaqAccordion from "../components/FaqAccordion/FaqAccordion";

const ContactPage = () => {
  const toast = useToast();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur" | "onChange" | "onSubmit",
  });
  const contactRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [afterRequest, setAfterRequest] = useState(false);

  useEffect(() => {
    const background = contactRef.current.querySelectorAll(".background");

    const contactForm = contactRef.current.querySelectorAll(".contact-form");
    const fadeInElements = contactRef.current.querySelectorAll(".fadeInBottom");

    gsap
      .timeline({ defaults: { delay: 0.5 } })
      .set(contactRef.current, { opacity: 1 })
      .from(
        background,
        {
          opacity: 0,
          duration: 0.4,
        },
        0,
      )
      .from(
        fadeInElements,
        {
          opacity: 0,
          y: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.3,
      )
      .from(
        contactForm,
        {
          opacity: 0,
          x: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.6,
      );
  }, []);

  const handleFormSubmit = data => {
    setLoading(true);
    setAfterRequest(false);

    setTimeout(() => {
      fetch(process.env.GATSBY_API_URL + "/landing/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          if (response.ok) {
            setLoading(false);
            setAfterRequest(true);
            setTimeout(() => {
              setAfterRequest(false);
              reset();
            }, 3000);
            toast({
              title: "Message sent",
              status: "success",
              duration: "3000",
              isClosable: false,
              position: "top",
            });
          } else {
            setLoading(false);
            setAfterRequest(true);
            setTimeout(() => {
              setAfterRequest(false);
              reset();
            }, 3000);
            toast({
              title: "Something went wrong",
              description: "Please try again later",
              status: "error",
              duration: "3000",
              isClosable: false,
              position: "top",
            });
          }
        })
        .catch(e => {
          setLoading(false);
          setAfterRequest(true);
          console.error(e.message);
          setTimeout(() => {
            setAfterRequest(false);
            reset();
          }, 3000);
          toast({
            title: "Something went wrong",
            description: "Please try again later",
            status: "error",
            duration: "3000",
            isClosable: false,
            position: "top",
          });
        });
    }, 1500);
  };
  return (
    <Layout>
      <Seo />
      <Box
        ref={contactRef}
        pb="144px"
        pt={{ base: "72px", "3xl": "80px" }}
        overflow="hidden"
        width="100%"
        gridColumn="1/-1"
        position="relative"
        px={{ base: "6", md: "10", lg: "20", "3xl": "40" }}
      >
        <Grid
          templateRows={{ base: "repeat(4, auto)", md: "repeat(2, auto)" }}
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          maxWidth={{ base: "unset", "3xl": "1600px" }}
          gap={{ base: "36px", md: "80px" }}
        >
          <GridItem zIndex="38" pt={{ base: "80px", md: "172px" }}>
            <VStack
              className="fadeInBottom"
              spacing={{ base: "6", md: "8" }}
              maxW="644px"
              alignItems="left"
            >
              <Heading
                as="h1"
                fontSize={{
                  base: "28px",
                  md: "36px",
                  xl: "48px",
                  "3xl": "64px",
                }}
                mb={{ base: "0", "3xl": "6" }}
              >
                Contact Motar.me
              </Heading>
              <Text fontSize={{ base: "sm", "3xl": "lg" }}>
                Want to start using Motar.me?
                <br />
                Feel free to contact us and talk about your needs.
              </Text>
              <HStack spacing="2">
                <Icon boxSize="24px" as={IoMailSharp} color="gray.500" />
                <Text
                  fontSize={{ base: "sm", "3xl": "lg" }}
                  as="a"
                  href="mailto:info@motar.me"
                >
                  info@motar.me
                </Text>
              </HStack>
              <OvalIcon />
            </VStack>
          </GridItem>
          <GridItem zIndex={33} pt={{ md: "80px" }}>
            <Box
              background="white"
              p={{ base: "4", md: "6", "3xl": "8" }}
              maxW={{md:"600px"}}
              className="contact-form"
            >
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Stack spacing="4">
                  <InputField
                    aria="First name"
                    label="First name"
                    placeholder="Enter your name"
                    name="name"
                    error={errors.name}
                    type="text"
                    isRequired={true}
                    {...register("name", {
                      required: {
                        value: true,
                        message: ERROR_MESSAGES.REQUIRED,
                      },
                      minLength: {
                        value: 3,
                        message: ERROR_MESSAGES.MIN_LENGTH(3),
                      },
                      maxLength: {
                        value: 50,
                        message: ERROR_MESSAGES.MAX_LENGTH(50),
                      },
                    })}
                  />
                  <InputField
                    aria="Last name"
                    label="Last name"
                    placeholder="Enter your name"
                    name="lastName"
                    error={errors.lastName}
                    type="text"
                    isRequired={true}
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: ERROR_MESSAGES.REQUIRED,
                      },
                      minLength: {
                        value: 3,
                        message: ERROR_MESSAGES.MIN_LENGTH(3),
                      },
                      maxLength: {
                        value: 50,
                        message: ERROR_MESSAGES.MAX_LENGTH(50),
                      },
                    })}
                  />
                  <InputField
                    aria="Email"
                    label="E-mail"
                    placeholder="Enter e-mail"
                    name="email"
                    type="email"
                    error={errors.email}
                    isRequired={true}
                    {...register("email", {
                      required: {
                        value: true,
                        message: ERROR_MESSAGES.REQUIRED,
                      },
                      minLength: {
                        value: 4,
                        message: ERROR_MESSAGES.MIN_LENGTH(4),
                      },
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: ERROR_MESSAGES.EMAIL,
                      },
                    })}
                  />
                  <TextareaField
                    aria="Description"
                    label="Description"
                    placeholder="Type your message"
                    name="message"
                    type="text"
                    error={errors.message}
                    isRequired={true}
                    {...register("message", {
                      required: {
                        value: true,
                        message: ERROR_MESSAGES.REQUIRED,
                      },
                      minLength: {
                        value: 4,
                        message: ERROR_MESSAGES.MIN_LENGTH(4),
                      },
                    })}
                  />

                  <Flex justifyContent="end" pt={{ lg: "2", "3xl": "4" }}>
                    <Button
                      isLoading={loading}
                      loadingText="Sending"
                      type="submit"
                      size="lg"
                      disabled={loading || afterRequest}
                    >
                      Send Message
                    </Button>
                  </Flex>
                </Stack>
              </form>
            </Box>
          </GridItem>
          <GridItem pt={{ md: "80px" }}>
            <Heading as="h2" size="xl" mb="10px">
              Frequently Asked Questions
            </Heading>
            <Text fontSize="sm" textAlign="justify" maxW={{md:"600px"}}>
              Here are some questions that might occur before using Motar.me. We
              hope the answers provided help solve your questions but if you
              still need help, please contact us using the form above.
            </Text>
          </GridItem>
          <GridItem pt={{ md: "80px" }}>
            <Box maxW={{md:"600px"}}>
              <FaqAccordion />
            </Box>
          </GridItem>
        </Grid>
        <Box
          className="background"
          width={{ base: "67vw", md: "60vw" }}
          right="0"
          top="0"
          height={{ base: "433px", md: "516px", lg: "568px", "3xl": "584px" }}
          background="linear-gradient(161.1deg, #CDF7FF 12.75%, #7BDBEE 102.89%)"
          borderRadius="0px 0px 300px 0px"
          transform="matrix(-1, 0, 0, 1, 0, 0)"
          position="absolute"
          zIndex="30"
        />
      </Box>
    </Layout>
  );
};

export default ContactPage;
