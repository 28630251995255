import * as React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import ChevronUpIcon from "../../images/vector/chevron_up.svg";
import ChevronDownIcon from "../../images/vector/chevron_down.svg";

const FaqAccordion = () => {
  const faqList = [
    {
      title: "What is Motar.me?",
      description:
        "Motar.me is a Sales Engagement platform which allows you to create, save, send and track personalised emails. Automating your emails with Motar.me makes it easy for your Sales team to send sales messages at scale. Directly integrate with your Gmail or Microsoft account or engage with prospects via Amazon Web Services. Reach out to your target audience more effectively with Motar.me.",
    },
    {
      title: "What can I do with Motar.me?",
      description: (
        <UnorderedList>
          <ListItem>Create email templates.</ListItem>
          <ListItem>Import contact lists/ add contacts manually.</ListItem>
          <ListItem>
            Create and send a set of emails together in a sequence.
          </ListItem>
          <ListItem>
            Schedule each email to be sent at your preferred time.
          </ListItem>
          <ListItem>
            Generate detailed reports showing open rate, number of recipients,
            status of recipients and who has opened emails.
          </ListItem>
          <ListItem>
            Generate a Tracking sheet within a specified period of time.
          </ListItem>
          <ListItem>Automatically record unsubscribes and bounces.</ListItem>
          <ListItem>
            Enables you to record responses - positives, neutrals and negatives
            meaning anyone who has responded will not receive the next email in
            the sequence.
          </ListItem>
        </UnorderedList>
      ),
    },
    {
      title: "How much does it cost to sign up with Motar.me?",
      description:
        "When you first sign up with Motar.me you have a 14-day free trial. The professional package costs €55 per user per month if paid monthly. By paying annually the professional package costs €45 per user per month, saving a user €120. We also offer an Enterprise Package which you can find out more about by contacting Motar.me here.",
    },
    {
      title: "How can I integrate my Gmail or Microsoft account with Motar.me?",
      description:
        "When you create your account and log into Motar.me, in the settings page under integrations you have the option to directly integrate with your Gmail or Microsoft account or both. For Microsoft, click 'Sign in with Microsoft', select your account and then check off all boxes and click continue. It is the same process for Gmail. You can revoke access at any time. Emails can be sent via Amazon Web Services (AWS) also. This is done by clicking 'Use with Amazon Web Services' and enter your email. An activation link will then be sent to the email address provided and once you verify by clicking the link you can then use AWS to send emails.",
    },
    {
      title: "How to create emails in a Sequence?",
      description:
        "In the email campaign creator section when you click 'create email' a message box will appear where you can either upload a template you have saved or type the email manually. Once you're happy with the email you then click create email and it is shown as email 1. You do the same process for adding the next email in sequence; click 'Create email', enter your email message then again click 'Create email' and it will be added as email 2. You can add as many emails as you would like to each campaign sequence.",
    },
    {
      title: "What happens when I mark a recipient as a positive?",
      description:
        "In a campaign sequence, when a recipient has a status (positive, neutral, negative, unsubscribe, bounce or other) they do not receive the next email in the sequence. They are removed from further follow up emails you have created in that campaign sequence.",
    },
    {
      title: "Can I import a contact list?",
      description:
        "Yes. In contact lists, it says 'Import contacts' in the top right corner. Once clicked you can upload a file. The file must be saved in excel format with the headings: lead_company, first_name, last_name, email, job_title, country. You can also add contacts manually by clicking 'Create list' in the contact lists section.",
    },
  ];

  return (
    <Accordion>
      {faqList.map(({ title, description }, index) => (
        <AccordionItem
          borderTopWidth={index === 0 ? "none" : "1px"}
          borderBottom="none"
          key={title}
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton px="8" py="4">
                  <Box fontWeight="bold" flex="1" textAlign="left">
                    {title}
                  </Box>
                  {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </AccordionButton>
              </h2>
              <AccordionPanel
                px={8}
                pb={4}
                fontSize="sm"
                color="gray.500"
              >
                {description}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FaqAccordion;
